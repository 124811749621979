import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";

import Layout from "../shared/layout";
import SEO from "../shared/seo";

const NotFoundPage: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <Layout>
      <SEO title="404" />
      <div className="w-100 text-center">
        <h1 className="text-center m-5">⛔</h1>
        <h1 className="text-center m-5">404</h1>
        <p className="text-center m-2">{formatMessage({ id: "common.not-found" })}</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
